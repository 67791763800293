import React, { useEffect, useState } from "react"
import { Card, Container } from "react-bootstrap"
import ActionBar from "../core/ActionBar"
import { useTranslation } from "react-i18next"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes"
import AddEditCustomer from "./AddEditCustomer"

function CustomerList() {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const [records, setRecords] = useState<any>([])
  const [selectedRecord, setSelectedRecord] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      const request = await API.getAction(
        "customer/list?OrganizationId=" + user.OrganizationId
      )
      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }
  const toggleAddEdit = async obj => {
    setSelectedRecord(obj)
    setShowModal(!showModal)
    if (showModal) {
      bindDataRemote()
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])
  return (
    <>
      {showModal && (
        <AddEditCustomer
          record={selectedRecord}
          toggle={toggleAddEdit}
          show={showModal}
        />
      )}
      <Container className="py-4 ">
        <ActionBar
          title={t("Customers")}
          icon="fa fa-users "
          actions={
            <div className="btn-group">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  toggleAddEdit(null)
                }}
              >
                <i className="fa fa-plus " />
              </button>
            </div>
          }
        ></ActionBar>
        <Card className="mt-4">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Email")}</th>
                    <th>{t("Phone")}</th>
                    <th>{t("Address")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  )}
                  {records &&
                    records.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td
                            className="clickable"
                            onClick={() => {
                              let p = `${Routes.CustomerDetails.clean}${item.id}`
                              console.log("url", p)
                              debugger
                              history.push(p)
                            }}
                          >
                            {item.name}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>{item.address}</td>
                          <td className="text-end">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  toggleAddEdit(item)
                                }}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default CustomerList
